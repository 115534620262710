@tailwind base;
@tailwind components;
@tailwind utilities;
@import "swiper/css";
@import "swiper/css/navigation";
/* @import "~react-image-gallery/styles/css/image-gallery.css"; */
@import "~@flaticon/flaticon-uicons/css/all/all";

body {
  background: #F2F2F2;
  scroll-behavior: smooth;
}

input:focus,
textarea:focus {
  outline: none;
}

@font-face {
  font-family: "PT Sans";
  src: url("../src/assets/fonts/IBMPlexSansCondensed-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "IBM Plex Sans Condensed";
  src: url("../src/assets/fonts/IBMPlexSansCondensed-Regular.ttf") format("truetype");
  font-weight: 400;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}




/* Scrolllbar css */
.hidden-scrollbar {
  /**
    FIREFOX
  */
  scrollbar-width: none;

  /**
    CHROME, EDGE, OPERA and SAFARI
  */
  &::-webkit-scrollbar {
    width: 0px;
  }
}


.card-shadow {
  -moz-box-shadow: 0 0 30px #ccc;
  -webkit-box-shadow: 0 0 30px #ccc;
  box-shadow: 0 0 30px #ccc;
}

.card-shadow-dashboard {
  -moz-box-shadow: 0 0 10px #ccc;
  -webkit-box-shadow: 0 0 10px #ccc;
  box-shadow: 0 0 10px #ccc;
}
.normal-shadow {
  -moz-box-shadow: 0 0 6px #ccc;
  -webkit-box-shadow: 0 0 6px #ccc;
  box-shadow: 0 0 6px #ccc;
}

.p-dropdown-items-wrapper{
  padding: 8px 8px 20px 8px !important;
   height: 150px !important;
  margin-top: 12px !important;
  background-color: #E8F0FF !important;
  border-radius: 16px;
}
.p-dropdown-item {
  font-family: "IBM Plex Sans Condensed", sans-serif;
  margin-top: 8px;
}
.p-dropdown-item:hover {
  font-family: "IBM Plex Sans Condensed", sans-serif;
 color: #2E00FF !important;
}

.p-multiselect-items-wrapper{
  padding: 8px 8px 20px 8px !important;
   height: 150px !important;
  margin-top: 12px !important;
  background-color: #E8F0FF !important;
  border-radius: 16px;
}
.p-multiselect-item {
  font-family: "IBM Plex Sans Condensed", sans-serif;
  margin-top: 8px;
}
.p-multiselect-item:hover {
  font-family: "IBM Plex Sans Condensed", sans-serif;
 color: #2E00FF !important;
}
.p-multiselect-header{
  display : none !important;
}

.div1:hover + .div2 {
  opacity: 0.2;
}

.div2:hover {
  opacity: 0.2;
}

.p-inputtext {
  width: 100% !important;
  background-color: transparent !important;
  font-family: "PT Sans", sans-serif !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 1.4 !important;
  letter-spacing: 0px !important;
  text-transform: none !important;
  border: none !important;
  color: #232A2E !important;
}
.p-dropdown-label{
  color: #C0C0C0 !important;
}